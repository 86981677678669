import './App.css'
import { Spring, animated } from 'react-spring'
import Header from './Header'
import NetworkLink from './NetworkLink'
import React from 'react'
import ReactTooltip from 'react-tooltip';

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
//Modal.setAppElement('#yourAppElement')

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      isModalOpen: false,
    }
  }

  handleiMessageClick(e) {
    e.preventDefault()

    // To avoid the "protocol not supported" alert, fail must open another app.
    let mac = 'imessage:danielmuckerman@me.com'
    let iphone = 'sms:danielmuckerman@me.com'

    // Launches iMessage when option is clicked
    var iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
    var OSX = /(Macintosh)/g.test(navigator.userAgent)
    if (iOS) {
      window.location = iphone
    } else if (OSX) {
      window.location = mac
    } else {
      // Safety case, in case an incompatible device exposes the link
      alert(
        'Sorry, this option only works on iOS 5.0+ and OSX 10.8+!\n' +
          'If you want to contact me, try email!',
      )
    }
  }

  render() {
    return (
      <div className="App-header">
        <div id="contain">
          <Spring
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
            config={{ duration: 1000 }}
          >
            {(props) => (
              <animated.div id="content" style={props}>
                <Header name="Dan" />

                <p id="intro">
                  Hi, I'm Dan Muckerman. I'm a Software Developer at{' '}
                  <a href="https://www.abeyon.com" className="link">
                    Abeyon.
                  </a>
                </p>
              </animated.div>
            )}
          </Spring>

          <Spring
            from={{ opacity: 0, bottom: 0 }}
            to={{ opacity: 1, bottom: 20 }}
            config={{ duration: 750 }}
          >
            {(props) => (
              <animated.div id="content2" style={props}>
                <p id="networks">
                  <NetworkLink
                    name="Github"
                    link="https://github.com/flamwenco"
                    class="github"
                    iconName="github1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {}}
                  />
                  <NetworkLink
                    name="Gitea"
                    link="https://git.technicalincompetence.club/dan"
                    class="gitea"
                    iconName="git"
                    onClick={() => {}}
                  />
                  <NetworkLink
                    name="iMessage"
                    link="#"
                    class="imessage"
                    iconName="bubbles"
                    onClick={(e) => this.handleiMessageClick(e)}
                  />
                  <NetworkLink
                    name="Email"
                    link="mailto:danielmuckerman@me.com"
                    class="mail"
                    iconName="paperplane"
                    onClick={() => {}}
                  />
                </p>
              </animated.div>
            )}
          </Spring>
          <ReactTooltip effect="solid" border="true"/>
        </div>
      </div>
    )
  }
}

export default App
