import React from 'react'
import iconSet from './css/selection.json'
import IcomoonReact from 'icomoon-react'

class NetworkLink extends React.Component {
  render() {
    if (this.props.name === 'iMessage') {
      let iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
      let OSX = /(Macintosh)/g.test(navigator.userAgent)
      let display = 'none'

      if (iOS || OSX) {
        console.log('is an apple')
        display = 'inline'
      } else {
        console.log('not an apple')
      }
      return (
        <a
          data-tip={this.props.name}
          href={this.props.link}
          rel="me"
          className={this.props.class}
          style={{ display: display }}
          onClick={(e) => this.props.onClick(e)}
        >
          <IcomoonReact
            iconSet={iconSet}
            color="#fff"
            size="32"
            icon={this.props.iconName}
          />
        </a>
      )
    }

    return (
      <a
        data-tip={this.props.name}
        href={this.props.link}
        rel="me"
        className={this.props.class}
        onClick={(e) => this.props.onClick(e)}
      >
        <IcomoonReact
          iconSet={iconSet}
          color="#fff"
          size="32"
          icon={this.props.iconName}
        />
      </a>
    )
  }
}

export default NetworkLink
