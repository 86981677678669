import React from "react"

class Header extends React.Component {
    render() {
        return (
            <div id="header">
                <header>
                    <div className="App-name">{this.props.name}</div>
                </header>
            </div>
        );
    }
}

export default Header
