import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactTooltip from 'react-tooltip';
import App from './App';
// import Resume from './Resume';

ReactDOM.render(
  <React.StrictMode>
    <App />
    <ReactTooltip effect="solid" type="dark" border={true} />
  </React.StrictMode>,
  document.getElementById('root')
);
